import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import TylenolAutismWrapper from "@components/layouts/tylenolautismWrapper";
import MainBanner from "@components/tylenolautism/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const Lawsuits = dynamic(() => import("@components/tylenolautism/lawsuits"));
const LawsuitsBottom = dynamic(
    () => import("@components/tylenolautism/lawsuitsBottom"),
);

const SideEffects = dynamic(
    () => import("@components/tylenolautism/sideEffects"),
);

export default function TylenolAutismHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <TylenolAutismWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Lawsuits />
            <SideEffects />
            <LawsuitsBottom />
        </TylenolAutismWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
